<style lang="less" scoped>
.aboutUs {
  width: 100%;
  background: #070911;
  font-family: "PingFangSC-Regular", "PingFang SC";
}
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    height: 400px;
    background: rgba(15, 17, 24, 1);
    border-radius: 10px;
    padding: 20px 23px;
    margin-bottom: 80px;
    &-container {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 2px solid rgba(222, 230, 255, 0.29);
      &-main {
        width: 100%;
        height: 220px;
        margin-top: 70px;
        display: flex;
        &-left {
          width: 50%;
          height: 100%;
          border-right: 1px solid #dee6ff;
          text-align: center;
          line-height: 220px;
          img {
            width: 260px;
            vertical-align: middle;
          }
        }
        &-right {
          width: 50%;
          text-align: center;
          img {
            width: 58px;
          }
          p {
            width: 70%;
            margin-left: 15%;
            margin: 0 auto;
            &:nth-child(2) {
              font-size: 30px;
              font-weight: 600;
              color: rgba(222, 230, 255, 1);
              line-height: 20px;
              letter-spacing: 2px;
              margin: 12px auto;
            }
            &:nth-child(3) {
              font-size: 14px;
              font-weight: 400;
              color: rgba(222, 230, 255, 1);
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  .person-center-wrapper-selfInfo-title {
    width: 100%;
    padding-top: 28px;
    display: flex;
    justify-content: flex-start;
    height: 40px;
    font-size: 20px;
    // line-height: 40px;
    &-left {
      width: 4px;
      height: 20px;
      background: rgba(85, 119, 224, 1);
      border-radius: 5px;
    }
    &-right {
      font-size: 18px;
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      margin-left: 10px;
    }
  }
  .normal {
    font-size: 16px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    font-weight: 400;
    color: rgba(222, 230, 255, 1);
    line-height: 40px;
    letter-spacing: 2px;
  }
  &-third {
    margin-top: 101px;
    width: 100%;
    height: 498px;
    background: #0f1118;
    position: relative;
    .idc-fifth-container {
      width: 84%;
      margin-left: 8%;
      padding-top: 5%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  /deep/
    .idc-fifth-container.swiper-container-initialized.swiper-container-horizontal
    > .swiper-button-next,
  .swiper-button-prev {
    width: 51px;
    height: 51px;
    top: 50%;
    background: rgba(222, 230, 255, 1);
    border-radius: 50%;
    color: #0f1118;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    // margin-left: 50px;
    // margin-right: 50px;
    &:after {
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>

<template>
  <div class="aboutUs">
    <div class="aboutUs-details">
      <div class="aboutUs-details-first">
        <div class="aboutUs-details-first-container">
          <div class="aboutUs-details-first-container-main">
            <div class="aboutUs-details-first-container-main-left">
              <img src="../assets/price-1.png"
                   alt="">
            </div>
            <div class="aboutUs-details-first-container-main-right">
              <img src="../assets/pp-sen.png"
                   alt="">
              <!-- <img :src="'/api/static/'+company.logoImg"
                   alt=""> -->
              <p>
                浙江，丽水
                <!-- {{ company.brandName }} -->
              </p>
              <p>
                好山好水，自然好酒。古堰画乡花果酒，于唇齿间留恋，在心中醉眠。好山好水，自然好酒。
                <!-- {{ company.introduce }} -->
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="aboutUs-details-second">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            名品信息
          </div>
        </div>
        <p class="normal">
          <!-- {{ company.explain }} -->
          上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开。
        </p>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            名品优势
          </div>
        </div>
        <p class="normal">
          <!-- {{ company.adv }} -->
          上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开。
        </p>
      </div>
      <div class="aboutUs-details-third">
        <div class="idc-fifth-container">

          <div class="swiper-wrapper">
            <!-- {{imgArray}} -->
            <div class="swiper-slide">
              <img src="../assets/j2.png"
                   alt="">
            </div>
            <div class="swiper-slide">
              <img src="../assets/j2.png"
                   alt="">
            </div>
            <div class="swiper-slide">
              <img src="../assets/j2.png"
                   alt="">
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <Common-footer></Common-footer>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
      company: {
        brandName: '',
        introduce: '',
        explain: '',
        adv: '',
        logoImg: ''
      }
    }
  },
  created () {
    if (this.$route.params.from === 'jiangnan') {
      this.company = this.$route.params.brandObj;
    }
  },
  mounted () {
    new Swiper('.idc-fifth-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  computed: {
    imgArray () {
      return [this.company.previewUrl, this.company.previewUrl1, this.company.previewUrl2].filter(item => item !== '');
    }
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonFooter
  },
}
</script>
